import {Popover, Transition} from '@headlessui/react';
import {mdiChevronDown, mdiClose, mdiMagnify, mdiMenu} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import NextImage from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {Fragment, useContext, useState} from 'react';
import {getStrapiMedia} from '../lib/media';
import {GlobalContext} from '../pages/_app';
import MegaMenu from './banner/megaMenu';
import PageWidthConstraint from './pagewidthconstraint';

const Nav = () => {
  const {logo}: any = useContext(GlobalContext);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const router = useRouter();

  const DropDownMenu = ({active, label, subItems}) => (
    <Popover className="uppercase font-sans font-bold text-gray-theme space-x-5">
      <div className="relative z-60">
        <button className={cls('flex uppercase font-sans font-bold text-gray-theme block text-left text-gray-theme hover:text-gray-theme-dark',
          active ? 'border-b-4 border-yellow-theme' : 'pb-1 text-gray-theme')} onClick={() => {
          setServicesMenuOpen(false);
          setDropDownMenu((current) => !current);
        }}>
          {label}
          <Icon className="text-gray-theme" path={mdiChevronDown} size={1}/>
        </button>
        <Transition
          as={Fragment}
          appear={true}
          show={dropDownMenu}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel
            className="absolute inset-x-0 p-4 transition transform origin-top-right z-60 bg-white p-10 shadow-xl border-0 rounded-lg w-64">
            <div className="uppercase font-sans font-bold text-gray-theme flex flex-col">
              <div className="space-y-4 flex flex-col">
                {subItems.map((link, index) => (
                  <Link key={index} href={link.href}>
                    <button onClick={() => setDropDownMenu(false)} key={index}
                            className={cls('uppercase font-sans font-bold text-gray-theme block text-left text-gray-theme hover:text-gray-theme-dark pb-1 text-gray-theme')}>
                      {link.label}
                    </button>
                  </Link>))
                }
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
  // ok see how many '/' are in the url, if it's more than 1, then we're on a subpage, we still want to set the active class
  const links = [
    {href: '/', label: 'Home', active: false},
    {href: '/services', label: 'Services', active: false, megaMenu: true},
    {
      label: 'About', active: false, dropDown: true, subItems: [
        {href: '/about', label: 'About Us', active: false},
        {href: '/about/our-team', label: 'Our Team', active: false},
      ],
    },
    {href: '/contact', label: 'Contact us', active: false},
  ].map(link => {
    if (link.href === router.pathname) {
      return {...link, active: true};
    }
    if (link.subItems) {
      const newSubItems = [...link.subItems];
      return {
        ...link, subItems: newSubItems.map(link => {
          if (link.href === router.pathname) {
            return {...link, active: true};
          }
          return link;
        }),
      };
      // return {...link, active: true};
    }
    if (router.pathname.split('/').length > 1 && '/' + router.pathname.split('/')[1] === link.href) {
      return {...link, active: true};
    } else {
      return link;
    }
  });

  return (
    <Popover className="relative">
      <div className={cls('z-40 relative', router.pathname === '/' ? 'h-64 bg-gradient-to-b from-white' : 'bg-white md:h-36 h-28')}>
        <PageWidthConstraint className="flex justify-between md:py-4 py-2 items-center">
          {
            logo.data &&
            <>
              <Link href="/">
                <a className="md:block hidden">
                  <NextImage
                    src={getStrapiMedia(logo)}
                    width="152px"
                    objectFit="contain"
                    height="100%"
                    alt="icon"
                  />
                </a>
              </Link>
              <Link href="/">
                <a className="md:hidden block">
                  <NextImage
                    src={getStrapiMedia(logo)}
                    width="115px"
                    objectFit="contain"
                    height="100%"
                    alt="icon"
                  />
                </a>
              </Link>
            </>
          }
          <div className=" space-x-10 md:flex hidden">
            {links.map((link, index) => {
                if (link.megaMenu) {
                  return (
                    <button key={index} onClick={() => {
                      setServicesMenuOpen((current) => !current);
                      setDropDownMenu(false);
                    }}
                            className={cls('block uppercase font-bold font-sans text-gray-theme hover:text-gray-theme-dark',
                              link.active ? 'border-b-4 border-yellow-theme' : 'pb-1 text-gray-theme')}>
                      {link.label}
                    </button>
                  );
                } else if (link?.dropDown) {
                  return (
                    <DropDownMenu key={index} {...link}/>
                  );
                }
                return (
                  <Link key={index} href={link.href}>
                    <button onClick={() => {
                      setServicesMenuOpen(false);
                      setDropDownMenu(false)
                    }} key={index}
                            className={cls('uppercase font-sans font-bold text-gray-theme block text-left text-gray-theme hover:text-gray-theme-dark',
                              link.active ? 'border-b-4 border-yellow-theme' : 'pb-1 text-gray-theme')}>
                      {link.label}
                    </button>
                  </Link>);
              },
            )}
          </div>
          <Popover className="uppercase font-sans font-bold text-gray-theme flex space-x-5 md:hidden">
            <div>
              <button onClick={() => {
                setServicesMenuOpen(false);
                setMainMenuOpen((current) => !current);
                setDropDownMenu(false);
              }}>
                <Icon path={mdiMenu} className="text-gray-theme" size={1.5}/>
              </button>
              <Transition
                as={Fragment}
                appear={true}
                show={mainMenuOpen}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Popover.Panel static
                               className="absolute mx-5 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-40 bg-white p-10 shadow-xl border-0 rounded-lg">
                  <div className="uppercase font-sans font-bold text-gray-theme flex flex-col">

                    <button className="ml-auto" onClick={() => {
                      setMainMenuOpen((current) => !current);
                    }}>
                      <Icon path={mdiClose} className="ml-auto text-gray-theme" size={1.5}/>
                    </button>

                    <div className="space-y-5 flex flex-col">
                      {links.map((link, index) => {
                        if (link.megaMenu) {
                          return (
                            <button key={index} onClick={() => {
                              setMainMenuOpen(false);
                              setServicesMenuOpen((current) => !current);
                            }}
                                    className={cls('text-center uppercase font-bold text-gray-theme hover:text-gray-theme-dark ml-auto mr-auto',
                                      link.active ? 'border-b-4 border-yellow-theme' : 'text-gray-theme')}>
                              {link.label}
                            </button>
                          );
                        } else if (link?.dropDown) {
                          return (
                            <>
                              {link.subItems?.map((link, index) => (
                                <Link key={index} href={link.href}>
                                  <button onClick={() => setServicesMenuOpen(false)} key={index}
                                          className={cls('text-center uppercase font-bold text-gray-theme hover:text-gray-theme-dark ml-auto mr-auto',
                                            link.active ? 'border-b-4 border-yellow-theme' : 'text-gray-theme')}>
                                    {link.label}
                                  </button>
                                </Link>
                              ))}
                            </>
                            // <DropDownMenu key={index} {...link}/>
                          );
                        } else {
                          return (
                            <Link key={index} href={link.href}>
                              <button onClick={() => setServicesMenuOpen(false)} key={index}
                                      className={cls('text-center uppercase font-bold text-gray-theme hover:text-gray-theme-dark ml-auto mr-auto',
                                        link.active ? 'border-b-4 border-yellow-theme' : 'text-gray-theme')}>
                                {link.label}
                              </button>
                            </Link>
                          );
                        }
                      })}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>

          </Popover>

        </PageWidthConstraint>

      </div>
      <div className="absolute top-36 z-50 w-full">
        <Transition
          as={Fragment}
          show={servicesMenuOpen}
          enter="transition duration-300 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <MegaMenu setServicesMenuOpen={setServicesMenuOpen}/>
        </Transition>
      </div>
    </Popover>
  );
};


export default Nav;