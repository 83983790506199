import React, {useContext, useEffect, useState} from "react";
import {useRouter} from "next/router";
import {gridBackgroundColors, gridBorderColors, gridHoverBackgroundColors} from "./color-map";
import {fetchAPI} from "../lib/api";
import {GlobalContext} from "../pages/_app";

const setDataSection = async (breadcrumbs: any, pathnames: string[], index: number) => {
    let tempBreadcrumbs = {...breadcrumbs};

    const breadCrumbData = await fetchAPI(breadcrumbs['api'], {
        filters: breadcrumbs['filters'],
        fields: breadcrumbs['fields'],
    })
    tempBreadcrumbs['subTabs'] = breadCrumbData.data.map((item: any) => {
        let path = '/' + item.attributes.slug
        return {
            label: item.attributes.name,
            directory: path,
            bgColor: breadcrumbs['bgColor'],
            hoverBgColor: breadcrumbs['hoverBgColor'],
            borderColor: breadcrumbs['borderColor'],
            href: breadcrumbs['href'] + path,
            active: false
        }
    });

    return tempBreadcrumbs;
}

const setActiveItem = async (currentBreadcrumbs: any, pathnames: string[], index: number, router: any) => {
    let breadcrumbs = {...currentBreadcrumbs};
    breadcrumbs['active'] = true;

    if (breadcrumbs.data) {
        breadcrumbs = await setDataSection(breadcrumbs, pathnames, index)
    }

    if ('subTabs' in breadcrumbs) {
        breadcrumbs = await setSubTabsToActive(breadcrumbs, pathnames, index, router);
    }

    return breadcrumbs
}

const setSubTabsToActive = async (breadcrumbs: any, pathnames: string[], index: number, router: any) => {
    let tempBreadcrumbs = {...breadcrumbs};
    let newIndex = index + 1;

    if (tempBreadcrumbs['subTabs'] && tempBreadcrumbs['subTabs'].length > 0) {
        for (let breadcrumb in tempBreadcrumbs['subTabs']) {
            if (tempBreadcrumbs.subTabs?.[breadcrumb]?.directory === pathnames[newIndex]) {
                tempBreadcrumbs['subTabs'][breadcrumb] = await setActiveItem(tempBreadcrumbs['subTabs'][breadcrumb], pathnames, newIndex, router)
            }
        }
    }
    return tempBreadcrumbs
}

export const SiteMap = () => {
    const {
        sheep_nav_icon,
        cattle_nav_icon,
        deer_nav_icon,
    }: any = useContext(GlobalContext);
    const defaultSiteMap = {
        href: '/',
        directory: '/',
        label: 'Home',
        borderColor: 'border-green-dark',
        bgColor: 'bg-green-dark',
        hoverBgColor: 'hover:bg-green-dark',
        active: false,
        subTabs: [
            {
                href: '/services',
                directory: '/services',
                borderColor: 'border-green-dark',
                bgColor: 'bg-green-dark',
                hoverBgColor: 'hover:bg-green-dark',
                label: 'Services',
                active: false,
                subTabs: [
                    {
                        href: '/services/genetics',
                        directory: '/genetics',
                        borderColor: gridBorderColors[0],
                        bgColor: gridBackgroundColors[0],
                        hoverBgColor: gridHoverBackgroundColors[0],
                        label: 'Genetics',
                        active: false,
                        subTabs:
                            [
                                {
                                    href: '/services/genetics/sheep',
                                    directory: '/sheep',
                                    borderColor: gridBorderColors[0],
                                    bgColor: gridBackgroundColors[0],
                                    hoverBgColor: gridHoverBackgroundColors[0],
                                    label: 'Sheep',
                                    icon: sheep_nav_icon,
                                    iconHeight: '35px',
                                    mobileIconHeight: '17px',
                                    active: false,
                                    data: true,
                                    api: '/breeds',
                                    fields: ['slug', 'name'],
                                    filters: {
                                        type: 'sheep',
                                    }
                                },
                                {
                                    href: '/services/genetics/cattle',
                                    directory: '/cattle',
                                    borderColor: gridBorderColors[1],
                                    bgColor: gridBackgroundColors[1],
                                    hoverBgColor: gridHoverBackgroundColors[1],
                                    label: 'Cattle',
                                    icon: cattle_nav_icon,
                                    iconHeight: '32px',
                                    mobileIconHeight: '16px',
                                    active: false,
                                    data: true,
                                    api: '/breeds',
                                    fields: ['slug', 'name'],
                                    filters: {
                                        type: 'cattle',
                                    }
                                },
                                {
                                    href: '/services/genetics/deer',
                                    directory: '/deer',
                                    borderColor: gridBorderColors[2],
                                    bgColor: gridBackgroundColors[2],
                                    hoverBgColor: gridHoverBackgroundColors[2],
                                    label: 'Deer',
                                    icon: deer_nav_icon,
                                    iconHeight: '40px',
                                    mobileIconHeight: '28px',
                                    active: false,
                                    data: true,
                                    api: '/breeds',
                                    fields: ['slug', 'name'],
                                    filters: {
                                        type: 'deer',
                                    }
                                }
                            ]
                    },
                    {
                        href: '/services/r-and-d',
                        directory: '/r-and-d',
                        borderColor: gridBorderColors[1],
                        bgColor: gridBackgroundColors[1],
                        hoverBgColor: gridHoverBackgroundColors[1],
                        label: 'R&D',
                        active: false,
                        fields: ['slug', 'name'],
                        data: true,
                        api: '/researches'
                    },
                    {
                        href: '/services/sales-and-service',
                        directory: '/sales-and-service',
                        borderColor: gridBorderColors[2],
                        bgColor: gridBackgroundColors[2],
                        hoverBgColor: gridHoverBackgroundColors[2],
                        label: 'Sales & Service',
                        active: false,
                        data: true,
                        api: '/sales-and-service-items'
                    },
                ]
            },
            {
                href: '/about',
                directory: '/about',
                borderColor: gridBorderColors[0],
                bgColor: gridBackgroundColors[0],
                hoverBgColor: gridHoverBackgroundColors[0],
                label: 'About',
                active: false
            },
            {
                href: '/contact',
                directory: '/contact',
                borderColor: gridBorderColors[2],
                bgColor: gridBackgroundColors[2],
                hoverBgColor: gridHoverBackgroundColors[2],
                label: 'Contact Us',
                active: false
            }
        ]
    }
    const router = useRouter();
    const [breadcrumbs, setBreadcrumbs] = useState(defaultSiteMap);

    const myRe = /\[(.*?)\]/g;
    let m;
    let path = router.pathname;
    while ((m = myRe.exec(router.pathname)) !== null) {
        if (m.index === myRe.lastIndex) {
            myRe.lastIndex++;
        }
        if(m && m.length > 1) {
            const key = m[1];
            if(key in router.query) {
                path = path.replace(m[0], router.query[key] as string);
            }
        }
    }


    useEffect(() => {
        let pathnames = path.split('/').map(path => '/' + path);
        const breadCrumbsTree = async () => {
            let tempBreadcrumbs = await setActiveItem(defaultSiteMap, pathnames, 0, router)
            setBreadcrumbs(tempBreadcrumbs);
        }
        breadCrumbsTree();
    }, [path]);

    return breadcrumbs;
}