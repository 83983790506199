import Nav from "./nav";
import Footer from "./footer";

const Layout = ({ children }: any) => (
    <>
        <Nav/>
        <main>
            {children}
        </main>
        <Footer/>
    </>
);

export default Layout;