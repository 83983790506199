import '../styles/globals.css'
import type {AppProps} from 'next/app'
import App from "next/app";
import Head from "next/head";
import {fetchAPI} from "../lib/api";
import {getStrapiMedia} from "../lib/media";
import {createContext, useEffect, useMemo, useState} from 'react';
import Layout from "../components/layout";
import {OtherMetaTag} from '../types/page-types';

export const GlobalContext = createContext({});
export const MetaTagContext = createContext({
    setPageTitle: (title: string | undefined) => {

    },
    setMetaDescription: (description: string | undefined) => {

    },

    setOtherMetaTags: (tags: OtherMetaTag[]) => {

    },
})


const MyApp = ({Component, pageProps}: AppProps) => {
    const {global} = pageProps;

    const [pageTitle, setPageTitle] = useState<string>(global.attributes?.default_meta_tags?.page_title)
    const [metaDescription, setMetaDescription] = useState<string>(global.attributes?.default_meta_tags?.meta_description)
    const [otherMetaTags, setOtherMetaTags] = useState<OtherMetaTag[]>(global.attributes?.other_meta_tags)
    const metaTagContextFields = useMemo(() => ({
        setPageTitle: (title: string) => {
            setPageTitle(title)
        },
        setMetaDescription: (description: string) => {
            setMetaDescription(description)
        },
        setOtherMetaTags: (otherTags: OtherMetaTag[]) => {
            setOtherMetaTags(otherTags)
        }
    }), [pageTitle, metaDescription, otherMetaTags])

    useEffect(() => {
        async function setDraft() {
            await fetch('api/staging')
        }

        if(pageProps.environmentType === 'staging'){
            setDraft();
        }
    })

    return (
        <MetaTagContext.Provider value={metaTagContextFields}>
            <Head>
                <title>{pageTitle}</title>
                <meta name="description" content={metaDescription}/>
                {
                    otherMetaTags?.map(tag => {
                        if(tag?.type?.startsWith('og:')){
                            return <meta key={tag.type} property={tag.type} content={tag.description}/>
                        }
                        return <meta key={tag.type} name={tag.type} content={tag.description}/>
                    })
                }
                <link rel="icon" href={getStrapiMedia(global.attributes.favicon)}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
                      rel="stylesheet"/>
                <script
                  dangerouslySetInnerHTML={{
                      __html: `
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KXSN84P');`
                  }}/>
            </Head>
            <GlobalContext.Provider value={global.attributes}>

                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </GlobalContext.Provider>
        </MetaTagContext.Provider>

    )
}

MyApp.getInitialProps = async (ctx: any) => {
    // Calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(ctx);
    // Fetch global site settings from Strapi
    const globalRes = await fetchAPI("/global", {
        populate: {
            favicon: "*",
            logo: "*",
            logo_footer: "*",
            sheep_nav_icon: "*",
            cattle_nav_icon: "*",
            deer_nav_icon: "*",
            error_banner: "*",
            default_meta_tags: "*",
            other_meta_tags: "*"
        },
    });

    // Pass the data to our page via props
    //ctx.ctx, if getInitialProps is used on other pages
    let statusCode = 200;
    let hasGetInitialPropsRun = false
    let err = null
    if (ctx.ctx) {
        statusCode = ctx.ctx?.res?.statusCode
        hasGetInitialPropsRun = ctx.ctx?.hasGetInitialPropsRun
        err = ctx.ctx?.err
    }
    return {
        ...appProps, pageProps: {
            global: globalRes.data,
            statusCode: statusCode,
            environmentType: process.env.ENVIRONMENT_TYPE,
            hasGetInitialPropsRun: hasGetInitialPropsRun,
            err: err
        }
    };
};


export default MyApp
