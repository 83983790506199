export const gridBorderColors: string[] = [
    "border-cyan-logo",
    "border-green",
    "border-yellow-theme",
];
export const gridColors: string[] = [
    "text-cyan-logo",
    "text-green",
    "text-yellow-theme",
]
export const gridBackgroundColors: string[] = [
    "bg-cyan-logo",
    "bg-green",
    "bg-yellow-theme",
]
export const gridHoverBackgroundColors: string[] = [
    "hover:bg-cyan-logo",
    "hover:bg-green",
    "hover:bg-yellow-theme",
]