import React from "react";
import cls from "classnames";

export const BannerDivider = () => {
    return (
        <div className="w-7 border-b-3 border-white-theme lg:block hidden mt-5 mb-4"/>
    );
}

export const BannerDividerSmall = () => {
    return (
        <div className="w-4 border-b-3 border-white-theme lg:block hidden mt-5 mb-4"/>
    );
}

export const CallToActionDivider = () => {
    return (
        <div className="w-6 border-b-3 mt-5 border-gray-theme mb-4 mr-auto ml-auto"/>
    );
}

export const CallToActionDividerLeft = () => {
    return (
        <div className="w-6 border-b-3 mt-5 border-gray-theme mb-4"/>
    );
}

export const CustomColourDivider = ({className}: any) => {
    return (
        <div className={cls("w-6 border-b-3 mt-5 mb-4", className)}/>
    );
}
