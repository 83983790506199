import PageWidthConstraint from "../pagewidthconstraint";
import React, {forwardRef, useEffect, useState} from "react";
import {titleOneClasses} from "./title-classes";
import {BannerDividerSmall} from "./divider";
import cls from "classnames";
import Link from "next/link";
import {SiteMap} from "../site-map";
import NextImage from "next/image";
import {getStrapiMedia} from "../../lib/media";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";


const buildMegaMenu = (subTabs: []) => {
    let tempMegaMenu: any[] = [];
    subTabs.forEach((subTab: any) => {
        if (subTab.href === '/services') {
            tempMegaMenu.push(subTab.subTabs);
        }
    });
    if (tempMegaMenu.length > 0) {
        tempMegaMenu = buildMegaMenuColumn(tempMegaMenu.length, tempMegaMenu[0], tempMegaMenu);
    }
    return tempMegaMenu;
}

const buildMegaMenuColumn = (length: number, subTabs: any[], tempMegaMenu: any[]) => {
    subTabs.forEach((subTab: any) => {
        if (subTab.active) {
            tempMegaMenu.push(subTab.subTabs);
        }
    });
    if (tempMegaMenu.length < length + 1) {
        if (subTabs[0] && subTabs[0].subTabs) {
            tempMegaMenu.push(subTabs[0].subTabs);
        }
    }

    let containsSubtabs = false;
    tempMegaMenu[tempMegaMenu.length - 1]?.forEach((subTab: any) => {
        if (subTab.subTabs) {
            containsSubtabs = true;
        }
    });
    if (containsSubtabs) {
        tempMegaMenu = buildMegaMenuColumn(tempMegaMenu.length, tempMegaMenu[tempMegaMenu.length - 1], tempMegaMenu);
    }

    return tempMegaMenu;

}

const DesktopMegaMenu = ({megaMenu, setServicesMenuOpen}: any) => (
    <div className='h-110 w-full relative bg-green-dark md:block hidden'>
        <PageWidthConstraint className="h-full flex relative">
            <div className="w-36 border-b-8 border-yellow-theme lg:block hidden absolute"/>
            <div className="mt-auto mb-auto w-full">
                <div className="px-4">
                    <div className="flex justify-between">
                        <Link href='/services'>
                            <a>
                                <h2 className={cls(titleOneClasses)}>SERVICES</h2>
                            </a>
                        </Link>
                        <button className='ml-auto' onClick={() => {
                            setServicesMenuOpen((current: boolean) => !current)
                        }}>
                            <Icon path={mdiClose} className="ml-auto text-white" size={1.5}/>
                        </button>
                    </div>
                    <BannerDividerSmall/>
                </div>
                <div className='grid grid-cols-3 w-full'>
                    {
                        megaMenu.length > 0 &&
                        megaMenu.map((column: [], index: number) => (
                            <div key={index} className="flex flex-col">
                                {
                                    column.map((subTab: any) => (<DefaultButton subTab={subTab} key={subTab.href} setServicesMenuOpen={setServicesMenuOpen}/>))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </PageWidthConstraint>
    </div>
);
const DefaultButton = ({subTab, className, mobile, children, setServicesMenuOpen}: any) => {
    const activeClasses = subTab.bgColor + " border-b-2 " + subTab.borderColor
    return (
        <>
            <Link key={subTab.directory} href={subTab.href}>
                <button
                    onClick={() => setServicesMenuOpen(false)}
                    className={cls(className, "px-4 flex h-10 justify-between py-2 text-left text-white", subTab.hoverBgColor, subTab.active ? activeClasses : "border-b-2 border-white/10", mobile ? '' : 'm-px')}>
                    <span>{subTab.label}</span>
                    {subTab.icon &&
                      <div className={cls('self-start', mobile ? '' : '-mt-2 ')}>
                        <NextImage
                          src={getStrapiMedia(subTab.icon)}
                          width="32px"
                          objectFit='contain'
                          height={mobile ? subTab.mobileIconHeight : subTab.iconHeight}
                          alt="icon"
                        />
                      </div>
                    }

                </button>
            </Link>
            {children}
        </>
    )
}
const BigButton = ({subTab, className, setServicesMenuOpen}: any) => {
    const activeClasses = subTab.bgColor + " border-b-2 " + subTab.borderColor
    return (
        <Link key={subTab.directory} href={subTab.href}>
            <button
                onClick={() => setServicesMenuOpen(false)}
                className={cls(className, "px-4 flex h-16 text-xl justify-between py-4 text-left text-white", subTab.hoverBgColor, subTab.active ? activeClasses : "border-b-2 border-white/10")}>
                <span>{subTab.label}</span>
                {subTab.icon &&
                  <div className='-mr-10 self-start'>
                    <NextImage
                      src={getStrapiMedia(subTab.icon)}
                      width="100%"
                      objectFit='contain'
                      height={subTab.iconHeight}
                      alt="icon"
                    />
                  </div>
                }
            </button>
        </Link>
    )
}

const MobileMegaMenu = ({siteMap, columns, setServicesMenuOpen}: any) => {
    let cols = columns < 3 ? '' : 'flex space-x-1';
    let servicesSiteMap = siteMap?.subTabs.find((siteMap: any) => siteMap.directory === '/services');
    return (
        <div className='min-h-110 h-auto w-full relative bg-green-dark md:hidden block py-4 -mt-8 z-40'>
            <PageWidthConstraint className="h-full flex relative">
                <div className="w-36 border-b-8 border-yellow-theme lg:block hidden absolute"/>
                <div className="mt-auto mb-auto w-full">
                    <div className="px-4">
                        <div className="flex justify-between">
                            <Link href='/services'>
                                <a>
                                    <h2 className={cls(titleOneClasses)}>SERVICES</h2>
                                </a>
                            </Link>
                            <button className='ml-auto' onClick={() => {
                                setServicesMenuOpen((current: boolean) => !current)
                             }}>
                                <Icon path={mdiClose} className="ml-auto text-white" size={1.5}/>
                            </button>
                        </div>
                        <BannerDividerSmall/>
                    </div>
                    <div className='w-full'>
                        {
                            servicesSiteMap.subTabs?.length > 0 &&
                            servicesSiteMap.subTabs.map((subTab: any, index: number) => (
                                <div key={subTab.href}>
                                    <div className={cls(columns < 3 ? '' : 'mb-1', cols)} >
                                        <BigButton subTab={subTab}
                                                   setServicesMenuOpen={setServicesMenuOpen}
                                                   className={cls('transition-all duration-150', columns < 3 ? 'w-full ' : 'flex w-1/2')}/>
                                    </div>
                                    {
                                        subTab?.subTabs?.length > 0 &&
                                        subTab.subTabs.map((subTabTwo: any, index: number) => (
                                            <div className={cls(cols, 'w-full')} key={subTabTwo.href}>
                                                <DefaultButton subTab={subTabTwo}
                                                               setServicesMenuOpen={setServicesMenuOpen}
                                                               className={cls('transition-all duration-150', columns < 3 ? 'w-full' : 'w-1/2 relative')}
                                                               mobile={true}>
                                                    <div className='flex flex-col absolute left-1/2 w-9/20 bg-green-dark'>
                                                        {
                                                            subTabTwo?.subTabs?.length > 0 &&
                                                            subTabTwo.subTabs.map((subTabThree: any, index: number) => (
                                                                <DefaultButton setServicesMenuOpen={setServicesMenuOpen} subTab={subTabThree} key={index} className='w-full' mobile={true}/>
                                                            ))
                                                        }
                                                    </div>
                                                </DefaultButton>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </PageWidthConstraint>
        </div>
    )
};

const MegaMenu = ({setServicesMenuOpen}: { setServicesMenuOpen: any }, ref: any) => {
    const siteMap: any = SiteMap();
    const [megaMenu, setMegaMenu] = useState<any[]>([]);
    const [columnLength, setColumnLength] = useState<number>(0);

    useEffect(() => {
        let tempMegaMenu: any[] = buildMegaMenu(siteMap?.subTabs);
        setColumnLength(tempMegaMenu.length);
        setMegaMenu(tempMegaMenu);
    }, [siteMap]);

    return (
        <div ref={ref}>
            <DesktopMegaMenu megaMenu={megaMenu} setServicesMenuOpen={setServicesMenuOpen}/>
            <MobileMegaMenu siteMap={siteMap} columns={columnLength} setServicesMenuOpen={setServicesMenuOpen}/>
        </div>
    );
};


export default forwardRef(MegaMenu);