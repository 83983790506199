import { getStrapiURL } from "./api";

export function getStrapiMedia(media: any, conversion: string = '') {
    if(media.data){
        let { url } = conversion &&  conversion in media.data.attributes.formats ? media.data.attributes.formats[conversion] : media.data.attributes;
        const imageUrl = url.startsWith("/") ? getStrapiURL(url) : url;
        return imageUrl;
    }
    else{
        return '/NoImageAvailableIcon.png'
    }
}