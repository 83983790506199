import React, {useContext} from "react";
import PageWidthConstraint from "./pagewidthconstraint";
import {GlobalContext} from "../pages/_app";
import cls from "classnames";
import NextImage from 'next/image';
import {getStrapiMedia} from "../lib/media";
import {SocialMedia} from "./social-media";

const FooterHeading = ({text}: { text: string }) => <h6 className="text-lg font-bold text-white font-sans">{text}</h6>
const FooterParagraph = ({text, className}: { text: string, className?: string }) => <p style={{whiteSpace: "pre-wrap"}}
                                                                                        className={cls("text-white font-sans", className)}>{text}</p>
const Footer = ({}) => {

    const {
        logo_footer,
        physical_address,
        postal_address,
        email,
        google_plus,
        facebook,
        linkedin,
        instagram,
        phone,
        twitter,
        youtube,
    }: any = useContext(GlobalContext);
    return (
        <footer className="bg-gray-theme w-full ">
            <PageWidthConstraint className='sm:grid md:grid-cols-3 flex flex-col sm:space-y-0 space-y-5  sm:px-20 px-4 py-24'>
                {
                    logo_footer.data &&
                  <div className="block w-36">
                    <NextImage
                      src={getStrapiMedia(logo_footer)}
                      width="144px"
                      objectFit='contain'
                      height="100%"
                      alt="icon"
                    />
                  </div>
                }
                <div>
                    <FooterHeading text="Focus Genetics"/>
                    <FooterParagraph text={postal_address}/>
                    <FooterParagraph className='mb-2' text={physical_address}/>
                    <p style={{whiteSpace: "pre-wrap"}}
                       className="text-white font-sans">
                        <a href={'tel:'+phone}>{phone}</a> / <a href={'mailto:'+email}>{email}</a>
                    </p>
                </div>
                <div className='sm:place-self-end'>
                    <FooterHeading text="Social Media"/>
                    <SocialMedia color='white' instagram={instagram} facebook={facebook} linkedin={linkedin} youtube={youtube} twitter={twitter}
                                 google_plus={google_plus}/>
                </div>
            </PageWidthConstraint>
            <PageWidthConstraint className='-mt-5 pb-5'>
                <span className='text-white font-sans text-xs'>Copyright Focus Genetics Management Ltd. {new Date().getFullYear()}</span>
            </PageWidthConstraint>
        </footer>
    );
};

export default Footer;