import cls from 'classnames';

function PageWidthConstraint({children, noPadding = false, className = ''}: any) {
    return <div className={cls(
        `max-w-screen-2xl w-full ml-auto mr-auto`,
        noPadding ? '' : '2xl:px-20 lg:px-10 sm:px-8 px-4',
        className
    )}>{children}</div>;
}

export default PageWidthConstraint;