import Icon from "@mdi/react";
import {mdiFacebook, mdiGooglePlus, mdiInstagram, mdiLinkedin, mdiTwitter, mdiYoutube} from "@mdi/js";
import React from "react";

export function SocialMedia({color, instagram, linkedin, youtube, facebook, twitter, google_plus}
                                : { color: string, instagram:string , facebook:string, linkedin:string, youtube:string, twitter:string, google_plus:string}) {
    return (
        <div className="flex space-x-4 mt-5">
            {instagram &&
              <a href={instagram}>
                <Icon path={mdiInstagram} size={1} color={color}/>
              </a>
            }
            {linkedin &&
              <a href={linkedin}>
                <Icon path={mdiLinkedin} size={1} color={color}/>
              </a>
            }
            {youtube &&
              <a href={youtube}>
                <Icon path={mdiYoutube} size={1} color={color}/>
              </a>}
            {facebook &&
              <a href={facebook}>
                <Icon path={mdiFacebook} size={1} color={color}/>
              </a>
            }
            {twitter &&
              <a href={twitter}>
                <Icon path={mdiTwitter} size={1} color={color}/>
              </a>
            }
            {google_plus &&
              <a href={google_plus}>
                <Icon path={mdiGooglePlus} size={1} color={color}/>
              </a>
            }
        </div>
    )
}