import cls from "classnames";


const titleShared = "text-white sm:text-left text-center font-bold font-sans"
export const titleOneClasses = cls('lg:block hidden text-lg', titleShared);
export const titleTwoClasses = cls('lg:text-6xl text-4xl', titleShared);

const callToActionShared = "text-gray-theme font-sans"
export const callToActionTitleOneClasses = cls('text-lg font-bold', callToActionShared);
export const callToActionTitleTwoClasses = cls('text-4xl font-bold mb-5', callToActionShared);
export const callToActionParagraphClasses = cls('text-lg', callToActionShared);
